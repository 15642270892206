import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Dropdown, Layout } from "antd";
import Avatar from "../../../../src/assets/images/user.png";
import {
    DownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/app";
import { useNavigate } from "react-router-dom";
import { doLogout, setSessionTimeOut } from "../../../store/AuthSlice";
import { WebConstant } from "../../webPages/webConstant/WebConstant";

import CommonModal from "../../../components/Modals/CommonModal";

interface HeaderViewProps {
    collapsed: boolean;
    SetCollapsed: Dispatch<SetStateAction<boolean>>;
}

/**
 * Renders the header view component for the main layout containing login and dropdown.
 *
 * @return {ReactElement} The rendered header view component.
 */
const HeaderView: React.FC<HeaderViewProps> = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector((state: RootState) => state.AUTH.userDetail)
    const logo = useSelector((state: RootState) => state.HOME.pageSettings?.banner?.banner_logo)
    const [logOutModal, setLogOutModal] = useState(false)


    // call logout function
    const showModal = () => {
        setLogOutModal(true)
    };
    const closeModal = () => {
        setLogOutModal(false)
    }
    const logOut = () => {
        sessionStorage.removeItem("welcomeData");
        dispatch(doLogout()).then(() => {
            dispatch(setSessionTimeOut(false))
            setLogOutModal(false)
            navigate("/");
        });
    }

    const items = [
        { label: <div onClick={() => navigate("/dashboard")}>Dashboard</div>, key: "Dashboard" },
        { label: <div onClick={() => navigate("/user-profile")}>Profile</div>, key: "Profile" },
        { label: <div onClick={() => navigate("/change-password")}>Change Password</div>, key: "ChangePassword" },
        { label: <div onClick={() => showModal()}>Log Out</div>, key: "Logout" },
    ];

    return (
        <Layout.Header className="main__page__appheader">
            <div className="container">

                <div className="headerWrap">
                    <div className="app_header_left">
                        <div className="logoImg" onClick={() => navigate("/")}>
                            <img className="icon" src={WebConstant?.IMAGE_URL + logo} alt="logo" />
                        </div>
                    </div>
                    <div className="appheader__right">
                        <div className="userDropdown">
                            <Dropdown
                                menu={{ items }}
                                trigger={["click"]}
                                overlayClassName="userMenuList"
                            >
                                <div className="userMenuWrap">
                                    <span className="avatar">
                                        <img src={Avatar} alt="Avatar" />
                                    </span>
                                    <span className="userName">{userDetail?.name} {userDetail?.last_name}</span>
                                    <DownOutlined />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>

            </div>
            <CommonModal
                title={"Log Out?"}
                open={logOutModal}
                onCancel={closeModal}
                className="logOutModal"
                footer={[

                    <Button
                        key="1"
                        htmlType="button"
                        className=' red_border_common_btn'
                        onClick={() => logOut()}

                    >
                        Confirm
                    </Button>,
                    <Button key="2" htmlType="button" type="primary" className="common_button green_border_common_btn" onClick={closeModal}>
                        Cancel
                    </Button>,
                ]}
            >
                <p>Are you sure you want to Log Out?</p>
                <b>
                    &ldquo;{(userDetail?.name && userDetail?.name) + " " + (userDetail?.last_name && userDetail?.last_name)}&rdquo;
                </b>
            </CommonModal>
        </Layout.Header>
    );
}

export default HeaderView;
