import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import api from "./api";
import { AppThunk } from "./app";
import { stringEncryption } from "../config/Global";
import API_URL from "../config/ApiUrl";
import { loadMapApi } from "../components/Map/loadMapApi";

interface AuthState {
  appLoading: boolean;
  initialData: object | null;
  isLoading: boolean;
  mapScriptLoaded: boolean
  userDetail: any;
  token: string | null;
  profileProgress: any;
  sessionTimeOut: boolean;
}

const initialState = {
  appLoading: true,
  initialData: {},
  isLoading: false,
  mapScriptLoaded: false,
  userDetail: {},
  token: null,
  profileProgress: null,
  sessionTimeOut: false,
} as AuthState;

const AuthSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setInitialData: (state, action: PayloadAction<any>) => {
      state.initialData = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    setUserDetail: (state, action: PayloadAction<any>) => {
      state.userDetail = action.payload;
    },
    setMapScriptLoaded: (state, action: PayloadAction<any>) => {
      state.mapScriptLoaded = action.payload;
    },
    resetAuthStore: () => {
      return initialState; // Reset the state to the initial state
    },
    start: (state) => {
      state.isLoading = true;
    },
    success: (state, action: PayloadAction<any>) => {
      if(action.payload?.token!=undefined)
        {
          localStorage.setItem("token", action.payload.token);
        }
      state.isLoading = false;
    },
    failure: (state) => {
      state.isLoading = false;
    },
    setProfileProgress: (state, action: PayloadAction<any>) => {
      state.profileProgress = action.payload
    },
    setSessionTimeOut: (state, action: PayloadAction<any>) => {
      state.sessionTimeOut = action.payload;
    },
  },
});

export const {
  start,
  success,
  failure,
  resetAuthStore,
  setAppLoading,
  setInitialData,
  setUserDetail,
  setUserToken,
  setMapScriptLoaded,
  setProfileProgress,
  setSessionTimeOut
} = AuthSlice.actions;

// Async action to fetch todos from the API
export const doLogin =
  (action: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(API_URL.LOGIN, action);
        if (action?.remember) {
          const string = JSON.stringify({
            email: action.username,
            password: action.password,
          });
          localStorage.setItem("remember_me", stringEncryption(string));
        } else {
          localStorage.removeItem("remember_me");
        }
	      localStorage.setItem("timeIn",new Date().getTime().toString()); 
        dispatch(success(response.data));
        dispatch(setUserToken(response.data.token));
        dispatch(setUserOptions());
        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };

export const refreshToken = 
  (): AppThunk<any> => 
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.get(API_URL.REFRESH_TOKEN);
        dispatch(success(response.data.data));
        dispatch(setUserToken(response.data.data.token));
        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    }   
//Async action to fetch register api

export const doRegister =
  (action: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(API_URL.REGISTER, action);
        // const string = JSON.stringify({
        //   email: action.email,
        //   password: action.password,
        // });
        // console.log("string",string);
        
        // localStorage.setItem("remember_me", stringEncryption(string));

        dispatch(success(response.data));
        dispatch(setUserToken(response.data.token));

        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };

// Async action to fetch sync from the API
export const initialAppOptions = (): AppThunk<any> => async (dispatch) => {
  try {
    const token = localStorage.getItem("token") ?? null;
    let initialData = sessionStorage.getItem("initialData") && sessionStorage.getItem("initialData") !== "undefined" ? JSON.parse(sessionStorage.getItem("initialData") ?? "") : null;

    if (token) {
      dispatch(setUserToken(token));
      dispatch(setUserOptions()).catch((error: any) => error)
    }

    const googleMapScript = await loadMapApi();
    if (googleMapScript) {
      googleMapScript.addEventListener("load", function () {
        dispatch(setMapScriptLoaded(true));
      });
    }

    if (!initialData) {
      const response = await api.post(API_URL.COMMON.SYNC);
      sessionStorage.setItem("initialData", JSON.stringify(response.data));
      dispatch(setAppLoading(false));

      initialData = response.data;
    }

    dispatch(setInitialData(initialData));

    dispatch(setAppLoading(false));
    return Promise.resolve(initialData);
  } catch (error: any) {
    dispatch(setAppLoading(false));
    return Promise.reject(error.data);
  }
};

export const setUserOptions = (): AppThunk<any> => async (dispatch) => {
  try {
    const response = await api.get(API_URL.ME);
    const userData = response.data.user
    const formField = [
      "decline_to_answer_gender",
      "decline_to_answer_disabilities",
      "decline_to_answer_veteran_status",
      "decline_to_answer_race_id",
      "decline_to_answer_household_income",
      "gender",
      "race_id",
      "disabilities",
      "veteran_status_id",
      "location",
      "name",
      "last_name",
      "email",
      "dob",
      "mobile_no",
      "house_hold_income"
    ]
    const keys = Object.keys(userData);
    const value = []
    for (const key of keys) {

      if (formField.includes(key)) {
        value.push(response.data.user[key])
        const filterData: any = value.filter((item: any) => item !== null && item !== 0)        
        let newProgress: any
        if (filterData.length >= 10 && userData.mobile_no === null) {
          newProgress = 90
        } else {
          newProgress = (filterData.length / 10) * 100;
        }
        dispatch(setProfileProgress(newProgress))
      }
    }
    dispatch(setAppLoading(false));
    dispatch(setUserDetail(response.data.user));
    return Promise.resolve(response.data);
  } catch (error: any) {
    dispatch(setUserToken(null));
    dispatch(setAppLoading(false));
    localStorage.removeItem("token");
    return Promise.reject(error.data);
  }
};

export const doLogout = (): AppThunk<any> => async (dispatch) => {
  try {
    const response = await api.get(API_URL.LOGOUT);
    dispatch(setUserToken(null));
    dispatch(setUserDetail(null));
    dispatch(setInitialData(null));
    localStorage.removeItem("token");
    localStorage.removeItem("timeIn");
    localStorage.removeItem("timeOut");
    initialAppOptions();
    return Promise.resolve(response.data);
  } catch (error: any) {
    return Promise.reject(error.data);
  }
};

export const forgotPassword =
  (action: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(API_URL.FORGOT_PASSWORD, action);
        dispatch(success(response.data));

        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };

export const updatePassword =
  (otp: string, action: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(API_URL.RESET_PASSWORD(otp), action);
        dispatch(success(response.data));

        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };
export const verifyOtp =
  (data: string): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.get(API_URL.VERIFY_PASSWORD(data));
        dispatch(success(response.data));

        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };

export const changePassword =
  (payload: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(API_URL.CHANGE_PASSWORD, payload);
        dispatch(setUserToken(null));
        dispatch(setUserDetail(null));
        dispatch(setInitialData(null));
        localStorage.removeItem("token");
        localStorage.removeItem("timeIn");
        localStorage.removeItem("timeOut");
        dispatch(success(response.data));
        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };
export const deleteAccount = (): AppThunk<any> => async (dispatch) => {
  try {
    const response = await api.delete(API_URL.DELETE_ACCOUNT);
    dispatch(setUserToken(null));
    dispatch(setUserDetail(null));
    dispatch(setInitialData(null));
    localStorage.removeItem("token");
    localStorage.removeItem("timeIn");
    localStorage.removeItem("timeOut");
    initialAppOptions();
    return Promise.resolve(response.data);
  } catch (error: any) {
    return Promise.reject(error.data);
  }
};
export const verifyEmail = (data: string): AppThunk<any> =>
  async (dispatch) => {
    try {
      dispatch(start());
      const response = await api.get(API_URL.VERIFY_TOKEN(data));
      dispatch(success(response.data));
      return Promise.resolve(response.data);
    } catch (error: any) {
      dispatch(failure());
      return Promise.reject(error.data);
    }
  };



export const doRegisterInvitedUser =
  (user_id: string, action: any): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.post(API_URL.USER_REGISTER(user_id), action);
        // const string = JSON.stringify({
        //   email: action.email,
        //   password: action.password,
        // });
        // console.log("string",string);

        // localStorage.setItem("remember_me", stringEncryption(string));

        dispatch(success(response.data));
        dispatch(setUserToken(response.data.token));

        return Promise.resolve(response.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };


export const getRegisterDetails =
  (user_id: string): AppThunk<any> =>
    async (dispatch) => {
      try {
        dispatch(start());
        const response = await api.get(API_URL.GET_USER(user_id));
        dispatch(success(response.data));
        return Promise.resolve(response.data.data);
      } catch (error: any) {
        dispatch(failure());
        return Promise.reject(error.data);
      }
    };

const AuthSliceReducer = AuthSlice.reducer;
export default AuthSliceReducer;
